import React from "react";
import { Helmet } from "react-helmet";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";

const Home = () => {
  return (
    <>
      <Helmet>
        ‍<title>Ober India Chemicals - Waterproofing solutions</title>‍
        <meta
          name="description"
          content="Manufacturers of waterproofing solutions"
        />
        <meta
          property="og:title"
          content="Ober India Chemicals - Waterproofing solutions"
        />
        <meta
          property="og:description"
          content="Manufacturers of waterproofing solutions"
        />
        <meta property="og:image" content="../images/logo.png" />
        <meta property="og:url" content="oberwaterproofing.com" />
        <meta property="og:site_name" content="Ober India Chemicals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Waterproofing, Waterproofing chemicals, Waterproofing applicators, brush coat, cementitious waterproofing, basement waterproofing, brush bond, fosroc, dr fixit, myk"
        />
      </Helmet>
      <Hero />
      <Portfolio />
      <Services />
      <Intro />
      <Clients />
      <Cta />
    </>
  );
};

export default Home;
