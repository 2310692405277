import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsData from "../data/productsData";
import "../css/ProductPage.css";

const ProductPage = () => {
  const { productSlug } = useParams(); // Get the product slug from the URL

  const product = productsData.find((p) => p.slug === productSlug);

  const [selectedQuantity, setSelectedQuantity] = useState(
    product.quantities[0]
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };
  // Handle quantity change
  const handleQuantityChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = product.quantities.find(
      (q) => q.label === selectedValue
    );
    setSelectedQuantity(selectedOption);
  };

  if (!product) {
    return <h2>Product not found!</h2>;
  }

  // const breadcrumbs = [
  //   { label: "Home", link: "/" },
  //   { label: "Products", link: "/#products" },
  //   { label: product.name, link: `/products/${productSlug}` },
  // ];

  return (
    <>
      {/* <div className="breadcrumb-container">
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </div> */}
      <div className="product-display">
        <div className="product-container">
          {/* Product Image */}
          {/* <div className="product-image">
          <img src={product.image} alt={product.name} />
        </div> */}
          {/* Product Image Carousel */}
          <div className="product-image">
            <div className="carousel">
              <img
                src={product.images[currentImageIndex]}
                alt={`Product Image ${currentImageIndex + 1}`}
              />
              <div>
                <button onClick={handlePrevImage}>{"<"}</button>
                <button onClick={handleNextImage}>{">"}</button>
              </div>
            </div>
          </div>

          {/* Product Details */}
          <div className="product-details">
            <h2>{product.name}</h2>
            <p className="product-subtitle">{product.category}</p>

            {/* Price */}
            {/* <div className="price">
            <span>MRP: </span>
            <strong>₹{product.basePrice}</strong>
          </div> */}

            <div className="product-quantity-price">
              {/* Quantity Selector */}
              <div className="product-dropdown">
                <label htmlFor="quantity-select">Select Quantity: </label>
                <select
                  id="quantity-select"
                  value={selectedQuantity.label}
                  onChange={handleQuantityChange}
                >
                  {product.quantities.map((q) => (
                    <option key={q.label} value={q.label}>
                      {q.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Price Display */}
              <p className="product-price">
                <strong>MRP:</strong> INR {selectedQuantity.value}
              </p>
            </div>

            {/* Features & Benefits */}
            <div className="features">
              <h3>Features & Benefits:</h3>
              <ul>
                {product.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
            {/* Buy Now button */}
            <div className="flex justify-center my-4 w-lg">
              <a
                href={`https://wa.me/9052555510?text=Hi,%20I%20am%20interested%20in%20buying%20${product.name}%20(${selectedQuantity.label})%20for%20INR%20${selectedQuantity.value}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
              >
                Buy Now
                <svg
                  className="w-4 h-4 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
