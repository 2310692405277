import React from "react";
import { Helmet } from "react-helmet";

const ShoutOuts = () => {
  return (
    <>
      <Helmet>
        ‍<title>Waterproofing chemicals shoutouts</title>‍
        <meta
          name="description"
          content="waterproofing chemicals by Ober India"
        />
        <meta
          property="og:title"
          content="waterproofing chemicals by Ober India"
        />
        <meta
          property="og:description"
          content="waterproofing chemicals by Ober India"
        />
        <meta property="og:image" content="../images/logo.png" />
        <meta
          property="og:url"
          content="https://www.oberwaterproofing.com/shoutouts"
        />
        <meta property="og:site_name" content="Ober India Chemicals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Waterproofing, Waterproofing chemicals, Waterproofing applicators, brush coat, cementitious waterproofing, basement waterproofing, brush bond, fosroc, dr fixit, myk"
        />
      </Helmet>
      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <div className="blog-page-container">
            <div className="blog-card-list-container">
              <div className="blog-card">
                <div className="blog-card-content">
                  {/* <Link
              to={`/blogs/${blog.id}`}
              className="blog-card-button"
            > */}
                  <a href="https://www.1directory.org/">1Directory.org</a>
                  {/* </Link> */}
                  <a href="https://www.homebuildercontractor.com/">
                    Home Builder Directory
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoutOuts;
